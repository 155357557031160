.forgot-password-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0;
  margin: 0;
  width: 45rem;
  height: 38rem !important;
}

.forgot-password-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f6f6ff;
  flex: 1;
  width: 100%;
  height: 100%;
  border-radius: 2rem 0 0 2rem;
}

.forgot-password-logo > img {
  width: 7rem;
  height: 7rem;
  object-fit: contain;
}

.forgot-password-logo > h1 {
  font-family: "Raleway", sans-serif;
}

.forgot-password-logo > h1 > span {
  color: #f09000;
}

.forgot-password-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  background-color: #ffffff;
  border-radius: 0 2rem 2rem 0;
  height: 100%;
}

input,
button {
  padding: 0.5rem;
  margin: 0.25rem;
}

@media screen and (max-width: 720px) {
  .forgot-password-box {
    flex-direction: column;
    width: 80%;
    height: fit-content;
    padding: 0;
    margin: 3rem 0;
  }

  .forgot-password-logo {
    background-color: #ffffff;
    width: 100%;
    height: fit-content;
    border-radius: 2rem 2rem 0 0;
    padding: 2rem 0 0 0;
  }

  .forgot-password-form {
    width: 100%;
    border-radius: 0 0 2rem 2rem;
    padding: 1rem 0;
  }
}
