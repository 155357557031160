.login-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  position: relative;
  background-color: #001130;
  overflow: auto;
}

/* @media screen and (max-width: 720px) {
  .login-layout {
    justify-content: flex-start;
  }
} */
