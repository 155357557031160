.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #f09000; */
  width: "100%";
  height: 2rem;
  /*padding: 1rem 3rem;*/
}

.topbar > h3 {
  white-space: nowrap;
  color: white;
  flex: 1;
  /* text-align: center; */
  font-family: "Raleway";
  font-weight: bold;
}

.status {
  color: #ffffff;
  font-family: "Raleway";
  font-size: 0.8rem;
  font-weight: bold;
  background-color: #04a400;
  padding: 0.25rem 0.75rem;
  border: none;
  border-radius: 0.25rem;
}

.show-menu-icon {
  display: flex;
}

.hide-menu-icon {
  display: none;
}

@media screen and (max-width: 640px) {
  .topbar {
    max-width: 100%;
    padding: 1rem;
    display: none;
  }

  .topbar > h4 {
    word-wrap: break-word;
  }
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
