.datepicker-l,
.datepicker-m,
.datepicker-s {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
}

.datepicker-l {
  width: 890px;
}

.datepicker-m {
  width: 560px;
}

.datepicker-s {
  width: 330px;
}
