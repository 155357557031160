.dashboard {
  display: flex;
  width: 100%;
  overflow: auto;
  flex: 1;
  font-family: "Roboto", sans-serif;
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.dashboard-body {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.main-with-sidebar {
  display: flex;
  flex-direction: column;
  /* width: 83%; */
  width: 100%;
  height: 100%;
}

.main-without-sidebar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.info {
  display: flex;
  /*padding: 1rem 1.5rem;*/
  background-color: #f6f6ff;
  min-height: calc(100% - 45px);
}

.css {
  display: flex;
  padding: 0;
  margin: 0;
  background-color: #ffffff;
}

.App {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 640px) {
  .dashboard-body {
    flex-direction: column;
  }

  .main-with-sidebar {
    width: 100%;
  }
}
