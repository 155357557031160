@import url("./assets/fonts-sansation/index.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("/node_modules/react-grid-layout/css/styles.css");
@import url("/node_modules/react-resizable/css/styles.css");

@import "~react-big-calendar/lib/css/react-big-calendar.css";

html {
  box-sizing: border-box;
  font-size: 14px;
}

body {
  height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "SegoeUI", "Roboto",
    "Sansation", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-white {
  background-color: #ffffff;
}

.bg-light-yellow {
  background-color: #fef7ec;
}

.bg-light-purple {
  background-color: #f6f6ff;
}

.report-container {
  width: 100%;
  height: 100vh;
}

/* .rdrDateRangePickerWrapper button {
  margin: 0 !important;
} */

.rdrDay,
.rdrStaticRange {
  margin: 0 !important;
}
