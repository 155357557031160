.sign-up {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  margin: 0;
  /* padding: 0; */
  position: relative;
}

.sign-up-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0;
  margin: 0;
  width: 45rem;
}

.sign-up-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f6f6ff;
  width: 45%;
  height: 100%;
  border-radius: 2rem 0 0 2rem;
  background-color: #f09000;
}

.sign-up-logo > img {
  width: 7rem;
  height: 7rem;
  object-fit: contain;
  background-color: white;
  padding: 1rem;
  border-radius: 2rem;
}

.sign-up-logo > h1 {
  font-family: "Raleway", sans-serif;
}

.sign-up-logo > h1 > span {
  color: white;
}

.gettingstarted-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  background-color: #ffffff;
  border-radius: 0 2rem 2rem 0;
  /* padding: 1rem 0; */
}

@media screen and (max-width: 720px) {
  .sign-up {
    background-image: linear-gradient(#f09000, #fef7ec);
    justify-content: flex-start;
    height: max-content;
  }

  .sign-up-box {
    flex-direction: column;
    width: 80%;
    height: fit-content;
    padding: 0;
    margin: 3rem 0;
  }

  .sign-up-logo {
    background-color: #ffffff;
    width: 100%;
    height: fit-content;
    border-radius: 2rem 2rem 0 0;
    padding: 2rem 0 0 0;
  }

  .sign-up-logo > h1 > span {
    color: #f09000;
  }

  .gettingstarted-form {
    width: 100%;
    border-radius: 0 0 2rem 2rem;
    /* padding: 1rem 0; */
  }
}
