.mobileTopBar {
  display: none;
}

@media screen and (max-width: 640px) {
  .mobileTopBar {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    background-color: #001130;
    align-items: center;
    justify-content: space-between;
    height: 5rem;
    flex-shrink: 0;
  }
}
