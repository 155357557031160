/* import this on app.tsx */
:root {
  /* this is the default colors of the tailwind */
  --primary-50: #fefaf4;
  --primary-100: #fbdeb3;
  --primary-200: #f9d399;
  --primary-300: #f8c880;
  --primary-400: #f6bc66;
  --primary-500: #f5b14d;
  --primary-600: #f3a633;
  --primary-700: #f3a633;
  --primary-800: #f29b1a;
  --primary-900: #f09000;
  --primary-950: #c57600;
  --red-300: #ff0000;
  --red-50: #ffe4e2;
  --green-300: #3aa76d;
  --green-50: #c9ffea;
  --blue-300: #00215d;
  --blue-50: #f1f2ff;
  --yellow-300: #f2c94c;
  --yellow-50: #fff5b2;
}
