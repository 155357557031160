/*!* sansation regular *!*/
/*@font-face {*/
/*  font-family: "sansation";*/
/*  font-style: normal;*/
/*  font-display: swap;*/
/*  font-weight: normal;*/
/*  src: url("./files/sansation-regular.ttf") format("truetype");*/
/*}*/

/*!* sansation regular italic *!*/
/*@font-face {*/
/*  font-family: "sansation";*/
/*  font-style: italic;*/
/*  font-display: swap;*/
/*  font-weight: normal;*/
/*  src: url("./files/sansation-regular-italic.ttf") format("truetype");*/
/*}*/

/*!* sansation lighter *!*/
/*@font-face {*/
/*  font-family: "sansation";*/
/*  font-style: normal;*/
/*  font-display: swap;*/
/*  font-weight: 300;*/
/*  src: url("./files/sansation-light.ttf") format("truetype");*/
/*}*/

/* sansation ligther italic */
/*@font-face {*/
/*  font-family: "sansation";*/
/*  font-style: italic;*/
/*  font-display: swap;*/
/*  font-weight: 300;*/
/*  src: url("./files/sansation-light-italic.ttf") format("truetype");*/
/*}*/

/* sansation bold */
@font-face {
  font-family: "sansation";
  font-style: normal;
  font-display: swap;
  font-weight: bold;
  src: url("./files/sansation-bold.ttf") format("truetype");
}

/*!* sansation bold italic *!*/
/*@font-face {*/
/*  font-family: "sansation";*/
/*  font-style: italic;*/
/*  font-display: swap;*/
/*  font-weight: bold;*/
/*  src: url("./files/sansation-bold-italic.ttf") format("truetype");*/
/*}*/
