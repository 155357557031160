.skeleton {
  background-color: #979797;
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  0% {
    opacity: 0.05;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.05;
  }
}
