.login-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0;
  margin: 0;
  width: 45rem;
}

.login-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f6f6ff;
  width: 45%;
  height: 100%;
  border-radius: 2rem 0 0 2rem;
  background-color: #f09000;
}

.login-logo > img {
  /* width: 7rem;
  height: 7rem; */
  /* object-fit: contain; */
  /* background-color: white; */
  padding: 1rem;
  /* border-radius: 2rem; */
}

.login-logo > h1 {
  font-family: "Raleway", sans-serif;
}

.login-logo > h1 > span {
  color: white;
}

.login-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* width: 55%; */
  background-color: #ffffff;
  border-radius: 0 2rem 2rem 0;
  padding: 1.5rem 0;
}

.login-form > h1 {
  text-align: left;
  margin: 0;
  font-family: "Raleway";
  width: 80%;
}

.forgot-password-link {
  text-align: right;
  width: 80%;
  padding: 0;
  margin: 0;
  font-family: "Roboto";
  font-size: 14px;
}

.other-actions {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sign-up-link {
  color: #001130;
  font-family: "Roboto";
  font-size: 14px;
  padding-top: 2rem;
  text-align: center;
  height: 1rem;
}

.sign-up-link-2 {
  color: #001130;
  font-family: "Roboto";
  font-size: 14px;
  padding-top: 1rem;
  text-align: center;
  height: 1rem;
}

@media screen and (max-width: 720px) {
  .login-box {
    flex-direction: column;
    width: 80%;
    height: 80%;
    padding: 0;
    margin: 2rem 0 1rem 0;
  }

  .login-logo {
    width: 100%;
    border-radius: 2rem 2rem 0 0;
    padding: 2rem 0 0 0;
  }

  .login-form {
    width: 100%;
    border-radius: 0 0 2rem 2rem;
    padding: 1rem 0;
  }

  .forgot-password-link {
    padding-top: 1rem;
  }

  .sign-up-link {
    padding-bottom: 1rem;
  }
}
